<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">會員等級管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增會員等級
        </button>
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows">
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input type="search" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" clearable="true" @keyup="
              (e) => {
                if (e.$event.keyCode === 13) grid.refresh();
              }
            " @clear="grid.refresh()" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid ref="grid" v-bind="gridOptions">
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
            </vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
        </Grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "會員等級",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "Name",
          title: "等級名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "UpgradeAmount",
          title: "升級金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Discount",
          title: "折扣",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('memberGrade/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('memberGrade/query') : undefined,
        save: model
          ? (params) => model.dispatch('memberGrade/save', params)
          : undefined
      },
      modalConfig: { width: "60%", height: "auto", showFooter: true },
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入名稱" } } },
        { field: "UpgradeAmount", title: "升級金額", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入升級金額" } } },
        { field: "Discount", title: "折扣", span: 12, itemRender: { name: "$input", props: { type: "float", placeholder: "請輸入折扣 0-1, 例如: 九折則輸入0.1", min: 0, max: 1 } } },
        {
          field: "Description",
          title: "說明",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: {
              placeholder: "請輸入說明"
            },
          },
        }
      ],
      rules: {
        Name: [{ required: true }],
        UpgradeAmount: [{ required: true }],
        Discount: [{ required: true }]
      },
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  }
});
</script>
