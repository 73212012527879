
import { defineComponent, ref, reactive } from "vue";
import CloudFun from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "會員等級",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "Name",
          title: "等級名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "UpgradeAmount",
          title: "升級金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Discount",
          title: "折扣",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('memberGrade/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('memberGrade/query') : undefined,
        save: model
          ? (params) => model.dispatch('memberGrade/save', params)
          : undefined
      },
      modalConfig: { width: "60%", height: "auto", showFooter: true },
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入名稱" } } },
        { field: "UpgradeAmount", title: "升級金額", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入升級金額" } } },
        { field: "Discount", title: "折扣", span: 12, itemRender: { name: "$input", props: { type: "float", placeholder: "請輸入折扣 0-1, 例如: 九折則輸入0.1", min: 0, max: 1 } } },
        {
          field: "Description",
          title: "說明",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: {
              placeholder: "請輸入說明"
            },
          },
        }
      ],
      rules: {
        Name: [{ required: true }],
        UpgradeAmount: [{ required: true }],
        Discount: [{ required: true }]
      },
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  }
});
